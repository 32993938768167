<template>
  <div id="app" class="page">
    <Menu />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from "./components/meun.vue";
export default {
  components: {
    Menu,
  },
};
</script>
<style lang="less">
@import "./assets/normalize.css";

#app {
  display: flex;
  height: 100vh;
  background: #fafafa;
  .content {
    flex: 1;
    margin: 8px;
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    overflow: auto;
  }
}
</style>
