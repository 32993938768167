<template>
  <el-row class="menu_page">
    <el-col>
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#324057"
        text-color="#fff"
        active-text-color="#409eff"
        :default-active="activeIndex"
      >
        <template v-for="(item, index) in items">
          <el-menu-item
            :key="index"
            :index="index.toString()"
            @click="handleRouter(item.path, index)"
          >
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "left-menu",
  data() {
    return {
      items: [
        {
          name: "首页",
          path: "/index",
        },
        {
          name: "测试",
          path: "/test",
        },
        {
          name: "柜子调整",
          path: "/update",
        },
      ],
      activeIndex: "0",
    };
  },
  mounted() {
    const index = localStorage.getItem("activeIndex");
    if (index) {
      this.activeIndex = index;
      this.$router.push(this.items[this.activeIndex].path);
    } else {
      this.$router.push(this.items[0].path);
    }
  },
  methods: {
    handleRouter(path, index) {
      localStorage.setItem("activeIndex", index);
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="less">
.menu_page {
  height: 100%;
  min-height: 100%;
  width: 200px;
  .el-col {
    height: 100%;
    ul {
      height: 100%;
      background-color: #324057;
    }
  }
}
</style>
