import { get, post } from "@/utils/http";

export const getMachine = (params) => get("/lgyx/cms/cabinet/getPage", params);

export const stopTest = (id) => get(`/lgyx/api/cabinetTest/stop/${id}`);

export const randomTest = (id, times) =>
  get(`/lgyx/api/cabinetTest/doCabinetRandom/${id}/${times}`);

export const allTest = (id) =>
  get(`/lgyx/api/cabinetTest/doCabinetAllBox/${id}`);

export const axisTest = (id, x, y, num, times) =>
  get(`/lgyx/api/cabinetTest/cabinetByPreset/${id}/${x}/${y}/${num}/${times}`);

export const cabinetDetail = (id) =>
  get(`/lgyx/api/cabinetBox/listByCabinetId/${id}`);

export const cabinetUpdate = (data) =>
  post(`/lgyx/api/cabinetBox/setXYParam`, data);
