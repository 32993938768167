import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "index",
    component: () => import("../views/sys/index.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/sys/test.vue"),
  },
  {
    path: "/update",
    name: "update",
    component: () => import("../views/sys/update.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
